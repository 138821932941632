<template>
    <book-issue :issuereport="true"></book-issue>
</template>

<script>
import BookIssue from '../Books/BookIssue.vue'
export default {
    components: {BookIssue},

}
</script>